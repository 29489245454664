import React, { Component } from "react";
import Letter from "./Letter";
import promisedSetState from "utils/promisedSetState";
import promisedTimeout from "utils/promisedTimeout";
import TransitionContext from "TransitionContext";
import { easeOutCubic } from "constants/beziers";
import { white, black } from "constants/colors";
import "./App.css";

const defaultDuration = 500;
const factor = 15;
const halfFactor = Math.floor(factor / 2);
const translateZ = -200;

function genLetters() {
  const sen = "jaygoss@gmail.com".split("");

  return sen.map((e, i) => (
    <Letter key={`${e}_${i}_${Math.random()}`} letter={e} _index={i} />
  ));
}

export default class App extends Component {
  constructor(props) {
    super(props);
    const sentence = genLetters();
    this.nextSentence = this.nextSentence.bind(this);
    this.animateFigure = this.animateFigure.bind(this);
    this.sentenceTransitionDuration = this.sentenceTransitionDuration.bind(
      this
    );
    this.handleFigure = this.handleFigure.bind(this);
    this.animateInit = this.animateInit.bind(this);
    this.figure = null;
    this.init = true;
    this.lastSentenceLength = sentence.length;

    this.state = {
      show: false,
      color: 1,
      init: true,
      sentence,
    };
  }

  componentDidMount() {
    this.animateInit();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sentence.length !== prevState.sentence.length) {
      this.lastSentenceLength = prevState.sentence.length;
    }
    if (this.state.show !== prevState.show) this.animateFigure(this.state.show);
  }

  handleFigure(e) {
    this.figure = e;
  }

  async nextSentence() {
    await promisedSetState.call(this, {
      show: false,
    });
    await promisedTimeout(this.sentenceTransitionDuration(false));
    await promisedSetState.call(this, {
      color: this.state.color ? 0 : 1,
    });
    await promisedTimeout(defaultDuration);
    this.setState({ show: true });
  }

  sentenceTransitionDuration(show) {
    return (
      this.lastSentenceLength * (show ? factor : halfFactor) + defaultDuration
    );
  }

  async animateInit() {
    await promisedTimeout(defaultDuration);
    await promisedSetState.call(this, { color: 0 });
    await promisedTimeout(defaultDuration);
    await promisedSetState.call(this, { show: true, init: false });
  }

  animateFigure(show) {
    window.TweenLite.to(
      this.figure,
      this.sentenceTransitionDuration(show) / 1000,
      {
        z: show ? 0 : translateZ,
        ease: window.Power4.easeOut,
      }
    );
  }

  render() {
    const { sentence, show, color, init } = this.state;
    const backgroundColor = color ? white : black;
    const aColor = color ? black : white;
    const transition = `background-color ${
      show || init ? defaultDuration : this.sentenceTransitionDuration(false)
    }ms ${easeOutCubic}`;
    const aTransition = `color ${
      show || init ? defaultDuration : this.sentenceTransitionDuration(false)
    }ms ${easeOutCubic}`;

    return (
      <>
        <main
          style={{ backgroundColor, transition }}
          onClick={this.nextSentence}
        >
          <TransitionContext.Provider
            value={{ show, color, factor, defaultDuration, halfFactor }}
          >
            <figure ref={this.handleFigure}>{sentence}</figure>
          </TransitionContext.Provider>
        </main>
        <section>
          <a
            style={{ transition: aTransition, color: aColor }}
            href={"https://data.rip"}
          >
            data.rip
          </a>
          {/* <a
            style={{ transition: aTransition, color: aColor }}
            href={"/generator"}
          >
            auto-poet
          </a> */}
          <a
            style={{ transition: aTransition, color: aColor }}
            href={"https://www.github.com/jaydgoss"}
            target={"_blank"}
          >
            github
          </a>
        </section>
      </>
    );
  }
}
