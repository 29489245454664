import React, { useContext } from "react";
import TransitionContext from "TransitionContext";
import { easeOutCubic } from "constants/beziers";
import { white, black } from "constants/colors";
import "./Letter.css";

export default ({ letter, _index }) => {
  const {
    show,
    color: _color,
    factor,
    defaultDuration,
    halfFactor
  } = useContext(TransitionContext);
  const color = _color ? black : white;
  const opacity = `${show ? 1 : 0}`;
  const transition = `opacity ${defaultDuration}ms ${easeOutCubic} ${(show
    ? factor
    : halfFactor) * _index}ms`;

  return (
    <span
      style={{
        transition,
        opacity,
        color
      }}
    >
      {letter}
    </span>
  );
};
